<template>
  <div v-if="isActive" class="text-center mt-8">
    <div class="text-success text-lg font-medium">
      Active subscription
    </div>
    <div class="text-success text-sm">
      Renews at {{ subscriptionCurrentPeriodEndHr }}
    </div>
  </div>

  <div v-else class="text-center mt-8">
    <div class="text-danger text-lg font-medium">
      Inactive subscription
    </div>
    <div class="text-danger text-sm">
      Expired at {{ subscriptionCurrentPeriodEndHr }}
    </div>
  </div>

  <button v-if="subscriptionType == 'stripe'" class="link mt-4 mb-2" :disabled="!isButtonEnabled" @click.prevent="manageSubscription">Manage subscription</button>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { shallowRef, computed } from 'vue';
import { subscriptionCurrentPeriodEnd, subscriptionType, isActive } from '../user';
import { goToManageSubscription } from '../purchaseSubscriptionHelper';
import { formatDateLocale } from '../formatters';

const subscriptionCurrentPeriodEndHr = computed(() => {
  if (!subscriptionCurrentPeriodEnd.value) {
    return '';
  }

  return formatDateLocale(subscriptionCurrentPeriodEnd.value);
});

const isButtonEnabled = shallowRef(true);

const route = useRoute();

function manageSubscription() {
  const imageId = route.query?.imageId ? route.query?.imageId as string : null;

  goToManageSubscription({
    isButtonEnabled,
    imageId
  });
}
</script>
