import { shallowRef, ShallowRef } from 'vue';

export type PurchaseAfterLoginData = {
  promoCode: string;
  tierId: string;
  imageId?: string;
};

export const isWorkstationAuthorized = shallowRef(false);
export const isLoginPageVisible = shallowRef(false);
export const purchaseAfterLoginData: ShallowRef<PurchaseAfterLoginData | null> = shallowRef(null);

export function showLoginPageAndPurchase({ promoCode, tierId, imageId }: PurchaseAfterLoginData) {
  purchaseAfterLoginData.value = { promoCode, tierId, imageId };
  isLoginPageVisible.value = true;
}

export function showLogin() {
  purchaseAfterLoginData.value = null;
  isLoginPageVisible.value = true;
}

export function closeLogin() {
  purchaseAfterLoginData.value = null;
  isLoginPageVisible.value = false;
}
